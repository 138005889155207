import "./Map.css";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import * as tt from "@tomtom-international/web-sdk-maps";

const MAX_ZOOM = 17;
const MIN_ZOOM = 1; // Set a minimum zoom level to prevent the map from being fully zoomed out

function MapComponent(
  props: Readonly<{
    heightClass: string;
    postLoadCallback: () => void;
  }>
) {
  const { heightClass, postLoadCallback: loadCallback } = props;
  const delhiCoordinates = [77.1, 28.5561]; // New Delhi coordinates
  const cologneCoordinates = [7.1397, 50.8668]; // Cologne coordinates
  const mapElement = useRef();
  const [mapLongitude, setMapLongitude] = useState(delhiCoordinates[0]);
  const [mapLatitude, setMapLatitude] = useState(delhiCoordinates[1]);
  const [mapZoom, setMapZoom] = useState(3);
  const [map, setMap] = useState(null);

  const increaseZoom = () => {
    if (mapZoom < MAX_ZOOM) {
      setMapZoom(mapZoom + 1);
    }
  };

  const decreaseZoom = () => {
    if (mapZoom > MIN_ZOOM) {
      setMapZoom(mapZoom - 1);
    }
  };

  const updateMap = () => {
    if (map) {
      map.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
      map.setZoom(mapZoom);
    }
  };

  const toRadians = (degree) => (degree * Math.PI) / 180;
  const toDegrees = (radian) => (radian * 180) / Math.PI;

  const generateGreatCircleRoute = (start, end, numPoints = 100) => {
    const [lon1, lat1] = start.map(toRadians);
    const [lon2, lat2] = end.map(toRadians);

    const dLon = lon2 - lon1;

    const route = [];
    for (let i = 0; i <= numPoints; i++) {
      const f = i / numPoints;
      const A = Math.sin((1 - f) * dLon) / Math.sin(dLon);
      const B = Math.sin(f * dLon) / Math.sin(dLon);
      const x =
        A * Math.cos(lat1) * Math.cos(lon1) +
        B * Math.cos(lat2) * Math.cos(lon2);
      const y =
        A * Math.cos(lat1) * Math.sin(lon1) +
        B * Math.cos(lat2) * Math.sin(lon2);
      const z = A * Math.sin(lat1) + B * Math.sin(lat2);
      const lat = Math.atan2(z, Math.sqrt(x * x + y * y));
      const lon = Math.atan2(y, x);
      route.push([toDegrees(lon), toDegrees(lat)]);
    }

    return route;
  };

  useEffect(() => {
    const map = tt.map({
      key: `${import.meta.env.VITE_TOM_TOM_KEY}`,
      container: mapElement.current,
      center: [mapLongitude, mapLatitude],
      zoom: mapZoom,
      minZoom: MIN_ZOOM, // Set minimum zoom level
    });

    const routeCoordinates = generateGreatCircleRoute(
      delhiCoordinates,
      cologneCoordinates
    );

    const routeGeoJson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: routeCoordinates,
          },
        },
      ],
    };

    map.on("load", () => {
      setTimeout(() => {
        const cologneMarker = new tt.Marker()
          .setLngLat([cologneCoordinates[0], cologneCoordinates[1]])
          .addTo(map);
        const delhiMarker = new tt.Marker()
          .setLngLat([delhiCoordinates[0], delhiCoordinates[1]])
          .addTo(map);

        map.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: routeGeoJson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 6,
          },
        });

        // Fit bounds to markers
        const bounds = new tt.LngLatBounds();
        bounds.extend(delhiCoordinates);
        bounds.extend(cologneCoordinates);
        map.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
          maxZoom: MAX_ZOOM,
          linear: true,
        });

        loadCallback();
      }, 2500);
    });

    setMap(map);
    return () => map.remove();
  }, []);

  useEffect(() => {
    updateMap();
  }, [mapLongitude, mapLatitude, mapZoom]);

  return (
    <div ref={mapElement} className={`${heightClass} w-full rounded-lg`} />
  );
}

export default MapComponent;
