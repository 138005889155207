import React, { useEffect } from "react";
import MapComponent from "./MapComponent";
import { ProgressBar } from "react-loader-spinner";

function App() {
  const [mapLoaded, setMapLoaded] = React.useState(false);

  return (
    <div className="px-8 pt-4  flex flex-col gap-5 h-max w-full min-w-[100vw] items-center  min-h-screen ">
      {!mapLoaded && <DisplayProgressBar />}

      {mapLoaded && <h1>TomTom & React ❤️</h1>}

      <MapComponent
        heightClass={
          "h-[60vh] md:h-[70vh] lg:h-[80vh] px-16 md:px-16 lg:px-28 xl:px-24 2xl:px-28"
        }
        postLoadCallback={() => setMapLoaded(true)}
      />

      {mapLoaded && (
        <p className=" text-xs font-light">
          Tom Tom map displaying flight route between New Delhi Airport and
          Cologne Airport
        </p>
      )}
    </div>
  );
}

function DisplayProgressBar() {
  const [barHeight, setBarHeight] = React.useState(0);

  useEffect(() => {
    setBarHeight(Math.max(40, Math.min(60, window.innerWidth / 18)));
    console.log(barHeight);
  });
  return (
    <div className="flex flex-col justify-center items-center h-screen w-full -translate-y-[5vh]">
      <ProgressBar
        visible={true}
        height={barHeight}
        borderColor="#444"
        barColor="rgb(120,20,100)"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p className="text-xs lg:text-base xl:text-xl 2xl:text-2xl text-[rgb(120,20,100)]">
        Loading Map
      </p>
    </div>
  );
}

export default App;
